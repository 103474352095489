.form-control {
	display: flex;
	flex-direction: column;
	font-size: 1.3rem;
	margin: 0.75rem 0;
}

.form-column {
	display: flex;
	flex-direction: column;
	width: 50%;
}

.form-column-single {
	width: 100%;
}

.form-column-one {
	padding-right: 1rem;
}

.form-column-two {
	padding-left: 1rem;
}

.form-control label {
	font-weight: 600;
	padding-bottom: 0.25rem;
}

.form-control input + label,
.form-control label > span {
	font-weight: 400;
}

.form-control input,
.form-control select,
.form-control textarea {
	background-color: #eee;
	border: solid 1px #ccc;
	font-size: 1.3rem;
	padding: 0.5rem;
}

.form-control input:focus-visible,
.form-control textarea:focus-visible {
	outline-style: solid;
}

.form-control p {
	font-size: 1.3rem;
	margin: 0;
}

.form-link {
	color: #009119;
	cursor: pointer;
	font-weight: 600;
}

.edit-save-cancel {
	display: flex;
	justify-content: flex-end;
	margin-top: 1.5rem;
	width: 100%;
}

.user-sign-up-form .edit-save-cancel {
	align-items: flex-end;
	flex-direction: column;
}

.edit-save-cancel.with-delete {
	justify-content: space-between;
}

.error-text {
	color: red;
	font-size: 1.2rem;
	font-weight: 600;
}

.form-control input.invalid-input {
	background-color: #fff1f1;
	outline: solid 1px red;
}

.accessories-edit div {
	margin: 0;
}

.edit-card .accessories-edit button {
	font-size: 1.3rem;
	margin: 0 5px;
}

.accessory-cancel {
	cursor: pointer;
}

.delete-acc {
	color: red;
	cursor: pointer;
}

.user-sign-up-form {
	width: 400px;
}

.user-sign-up-form h2.sign-up-heading {
	background-color: #2f6e3a;
	color: white;
}

.form-control input.read-only-input {
	background-color: white;
	border: none;
}

.form-control input.read-only-input:focus-visible {
	outline: none;
}
