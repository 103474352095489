.card {
	--lightGreen: rgb(198, 238, 198);
	--lightRed: rgb(240, 177, 177);
	--lightYellow: rgb(240, 239, 177);
	background-color: white;
	border: solid 2px transparent;
	border-radius: 1rem;
	box-shadow: 2px 2px 5px 0 #aaa;
	display: flex;
	flex-direction: column;
	margin: 1rem 0;
	padding: 1rem;
}

.card.collected {
	border: solid 2px rgb(28, 153, 28);
}

.card.excluded {
	border: solid 2px rgb(217 104 104);
}

.card.not-complete {
	border: solid 2px rgb(185, 175, 22);
}

.compact.card {
	border-width: 3px;
	padding: 0.5rem;
}

.card h4 {
	margin: 0.25rem 0;
	font-size: 1.6rem;
}

.card h4 span {
	font-weight: 400;
}

.view-duplicates-from-card-link {
	color: #009119;
	cursor: pointer;
	font-weight: 400;
	text-decoration: underline;
}

.card p {
	margin: 0 0 1rem 0;
	font-size: 1.4rem;
}

.card p.accessory {
	margin-bottom: 0;
}

.card .compact-header {
	display: flex;
	justify-content: space-between;
}

.card-bottom {
	display: flex;
	height: 100%;
}

.card-left {
	flex: 4;
	padding-right: 1rem;
}

.card-right {
	flex: 5;
	background-color: #eee;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 2rem;
	border-radius: 1rem;
	border: solid 1px #ddd;
}

.card-right button.basic {
	align-self: flex-start;
	margin-top: 2rem;
}

.card.collected .card-right {
	background-color: rgb(221 235 221);
	border: solid 1px #abd5ab;
}

.card.not-collected .card-right {
	background-color: #f3f3d5;
}

.card-name {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.card-name h2 {
	cursor: pointer;
	font-size: 2rem;
	padding-bottom: 1rem;
	margin: 0;
}

.card-photo {
	min-height: 240px;
	text-align: center;
	width: 100%;
}

.card-photo img {
	border-radius: 1rem;
	cursor: pointer;
	height: auto;
	max-width: 100%;
}

.card-info {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.check {
	border-radius: 1rem;
	margin-bottom: 0.5rem;
	padding: 0.5rem 1.25rem;
}

.check p {
	margin: 0;
}

.check.yes {
	background-color: var(--lightGreen);
}

.check.no {
	background-color: var(--bodyColor);
}

.check.hidden {
	display: none;
}

.accessory .fa-solid.fa-x {
	color: red;
	margin-right: 0.4rem;
}

.accessory .fa-solid.fa-check {
	color: green;
	margin-right: 0.4rem;
}

.accessory > span {
	display: flex;
	align-items: center;
	padding: 0.3rem 0;
}

.compact .card-name {
	margin-left: 0.5rem;
}

.compact .card-name h2 {
	font-size: 18px;
	padding-bottom: 0.5rem;
}

.compact .card-right {
	display: none;
}

.compact .card-left {
	display: flex;
	padding-right: 0;
}

.compact .card-info {
	flex-direction: row;
	align-items: center;
}

.compact .card-photo {
	height: 85px;
	min-height: 0;
	width: auto;
}

.compact .card-photo img {
	height: 100%;
	width: auto;
}

.compact .card-info {
	flex: 3;
	flex-wrap: wrap;
}

.compact .card-info button {
	margin: 2px 0.5rem 0 0.5rem;
}

.compact .check {
	flex: none;
	margin: 2px 0.5rem 0 0.5rem;
}

.compact .check p {
	font-size: 13px;
}

.compact-variant {
	font-size: 1.4rem;
}

.card-controls {
	display: flex;
	margin-left: 1rem;
}

.card-controls i {
	align-self: flex-start;
	color: #aaa;
	cursor: pointer;
	font-size: 16px;
}

.card-controls i.fa-eye {
	margin-right: 0.75rem;
}

.card-controls i:hover {
	color: #666;
}

.tag {
	background-color: #ddd;
	border-radius: 5px;
	font-size: 1.4rem;
	font-weight: 400;
	margin-right: 0.5rem;
	padding: 0.5rem;
	position: relative;
	top: -10px;
}

.editing-label {
	font-weight: 400;
}

@media (max-width: 960px) {
	.card-controls {
		margin-bottom: 1rem;
	}

	.card-controls i {
		font-size: 20px;
	}

	.card-controls i.fa-eye {
		margin-right: 1.25rem;
	}

	.card-photo {
		min-height: 200px;
	}
}

@media (max-width: 640px) {
	.container div.card {
		border-width: 4px;
	}

	.card-name h2 {
		padding-right: 2rem;
	}
}

@media (max-width: 450px) {
	.card-photo {
		text-align: center;
		min-height: 175px;
	}

	.card-left {
		padding-right: 0;
	}

	.card-bottom {
		flex-direction: column;
	}

	.card-info {
		margin: 1rem 0;
	}

	.card-controls i.fa-eye {
		margin-right: 0.25rem;
	}

	.card-controls i.fa-pen-to-square {
		margin-left: 0.75rem;
	}

	.compact .card-left {
		flex-direction: column;
	}

	.card .compact-header {
		flex-direction: column;
	}

	.compact .card-name h2 {
		padding-right: 0;
		text-align: center;
	}

	.compact .card-controls {
		justify-content: center;
	}

	.compact .check {
		margin: 0 0 3px 0;
		text-align: center;
		width: 100%;
	}

	.compact .card-info {
		justify-content: center;
	}
}
