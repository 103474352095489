.variants-overlay {
	background-color: rgba(0, 0, 0, 0.95);
	left: 0;
	max-height: 100%;
	min-height: 100vh;
	overflow-y: auto;
	padding: 2rem;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 100;
}

.variants-heading {
	color: white;
	display: flex;
	justify-content: space-between;
	margin-top: 0;
}

.variant-close {
	align-items: center;
	color: lightgray;
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;
}

.variant-close div {
	cursor: pointer;
	padding: 1rem;
}

.variant-close .fa-close {
	cursor: pointer;
	font-size: 36px;
}

.variants-heading .fa-close:hover {
	color: white;
}

.variants-overlay .card {
	box-shadow: none;
}

@media (max-width: 640px) {
	.variants-heading .fa-close {
		background-color: black;
		border-radius: 100px;
		font-size: 3rem;
		padding: 0.5rem 1rem;
		position: fixed;
		right: 0.75rem;
		top: 0.75rem;
	}
}
