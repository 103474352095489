.modal-card.confirm-modal {
	border-radius: 1rem;
	margin-top: 8%;
	width: 35vw;
}

.confirm-modal h2 {
	background-color: rgb(215 45 45);
	border-radius: 1rem 1rem 0 0;
	color: white;
	font-size: 2rem;
}

.confirm-buttons {
	display: flex;
	justify-content: center;
	margin: 4rem 0;
}

.confirm-modal button {
	margin: 0 1rem;
	padding: 1rem 1.5rem;
}

@media (max-width: 960px) {
	.modal-card.confirm-modal {
		width: 98vw;
	}
}
