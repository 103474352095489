.collection-select-cont {
	background-color: #ddd;
	border-bottom: solid 1px #aaa;
	display: flex;
	border-radius: 3rem 3rem 0 0;
	justify-content: center;
	margin: -1rem -1rem 2rem -1rem;
	text-align: center;
}

.collection-select {
	display: flex;
	font-size: 1.5rem;
	margin-bottom: -1px;
}

.collection-select > div {
	border-bottom: solid 1px transparent;
	cursor: pointer;
	padding: 0.5rem 1.5rem;
}

.collection-select > div:hover {
	border-bottom: solid 2px rgb(28, 153, 28);
}

.collection-select > div.active {
	background-color: #eee;
	border-bottom: solid 2px rgb(28, 153, 28);
	cursor: default;
}

.collection-select > div > span {
	font-weight: 600;
	color: rgb(7 131 7);
}

@media (max-width: 960px) {
	.collection-select {
		flex-wrap: wrap;
		justify-content: center;
		padding: 0 3rem;
	}
}

@media (max-width: 640px) {
	.collection-select {
		padding: 1rem 3rem;
	}
}
