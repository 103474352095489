.collection-stats {
	align-items: center;
	color: #eee;
	display: flex;
	flex-wrap: wrap;
	font-size: 2rem;
	justify-content: flex-end;
}

.sign-up-button {
	margin-left: 1.5rem;
}

.log-in-link {
	cursor: pointer;
}

.param-user {
	font-weight: 600;
	color: rgb(75 217 75);
}

.collection-stats span {
	font-weight: 600;
	color: rgb(75 217 75);
}

.collection-stats > p,
.collection-stats > div {
	background-color: black;
}
.collection-stats > * {
	margin: 0 1rem;
}

.collection-stats > *:last-child {
	margin-right: 0;
}

.username-link {
	color: white;
	cursor: pointer;
	font-size: 1.4rem;
	text-decoration: none;
	background-color: rgba(100, 100, 100, 0.75);
	padding: 0.75rem;
	border-radius: 0.5rem;
}

.username-link:hover {
	background-color: #2f6e3a;
}

.profile-links {
	align-items: center;
	display: flex;
}

.profile-links .fa {
	cursor: pointer;
	font-size: 14px;
	margin-left: 1rem;
}

.collection-stats a:hover {
	background-color: #2f6e3a;
}

.collection-stats a.stat-link span {
	color: white;
}

.collection-stats > div:first-child {
	padding-left: 0;
}

.top-header {
	align-items: stretch;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 3rem;
}

.top-header-right {
	align-items: flex-end;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-right: 1rem;
}

.top-header-right .fa-search {
	display: none;
}

.menu-search-cont {
	align-items: center;
	display: flex;
}

.logo {
	cursor: pointer;
	max-width: 235px;
}

.logo > img {
	max-width: 100%;
}

.currently-viewing {
	background-color: #333;
	color: white;
	margin: -0.8rem -0.8rem 0 -0.8rem;
	padding: 0.5rem 0;
	position: sticky;
	text-align: center;
	top: 0;
	z-index: 50;
}

@media (max-width: 1350px) {
	.logo {
		max-width: 200px;
	}
}

@media (max-width: 960px) {
	.top-header {
		padding: 1rem 1rem 3rem;
	}

	.logo {
		max-width: 135px;
	}

	.menu-search-cont {
		display: none;
		margin-top: 0.5rem;
		position: absolute;
		top: 5rem;
	}

	.menu-search-cont input {
		font-size: 1.5rem;
		padding: 1rem;
	}

	.menu-search-cont.visible {
		display: flex;
	}

	.top-header-right .fa-search {
		display: block;
	}

	.currently-viewing{
		bottom: 0;
		left: 0;
		position: fixed;
		right: 0;
		top: auto;
	}
}

@media (max-width: 640px) {
	.top-header {
		align-items: center;
		flex-direction: column;
	}

	.top-header-right {
		margin: 0 0 2rem 0;
		order: 1;
	}

	.logo {
		order: 2;
	}
}

@media (max-width: 450px) {
	.menu-search-cont {
		left: 0;
		width: 100%;
	}

	.search-box {
		width: 100%;
	}

	.menu-search-cont input {
		width: 94vw;
	}
}
