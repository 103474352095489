.container {
	display: grid;
	grid-template-columns: repeat(auto-fill, 24%);
	justify-content: space-between;
	margin-top: 1rem;
}

.no-results {
	display: flex;
	justify-content: center;
	align-items: center;
}

.no-results div {
	background-color: #eee;
	border-radius: 2rem;
	color: #888;
	margin-top: 5%;
	padding: 2rem 5rem;
}

@media (max-width: 1700px) {
	.container {
		grid-template-columns: repeat(auto-fill, 32.5%);
	}
}

@media (max-width: 1350px) {
	.container {
		grid-template-columns: repeat(auto-fill, 48%);
	}
}

@media (max-width: 960px) {
	.container {
		grid-template-columns: repeat(auto-fill, 100%);
	}
}
