.spinner {
	align-items: center;
	display: flex;
	justify-content: center;
	width: 100%;
	margin-top: 10%;
}

.spinner.absolute {
	position: absolute;
	right: 50%;
	transform: translateX(50%);
}

.lds-ring {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}

.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 85px;
	height: 85px;
	margin: 8px;
	border: 8px solid rgb(28, 153, 28);
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: rgb(28, 153, 28) transparent transparent transparent;
}

.spinner.small {
	width: auto;
	margin: 0 1.5rem 0 0;
}

.spinner.small .lds-ring {
	width: 22px;
	height: 22px;
}

.spinner.small .lds-ring div {
	width: 22px;
	height: 22px;
	margin: 3px;
	border-width: 3px;
}

.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}

@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
