.tabs-cont {
	align-items: flex-end;
	color: white;
	display: flex;
	margin-bottom: calc(-3rem - 5px);
	width: 100%;
	justify-content: center;
	user-select: none;
}

.tab {
	padding: 1rem 2rem;
	background-color: #555;
	border-radius: 15px 15px 0 0;
	cursor: pointer;
	margin: 0 1px 0 0;
}

.tab:hover {
	background-color: gray;
}

.tab.active,
.tab.active:hover {
	background-color: rgb(28, 153, 28);
}

@media (max-width: 1350px) {
	.tabs-cont {
		margin-top: 2.5rem;
	}
}

@media (max-width: 960px) {
	.tabs-cont {
		flex-wrap: wrap;
		margin-top: 1.5rem;
		padding: 0 5rem;
	}

	.tab {
		border-radius: 0;
		font-size: 1.3rem;
		padding: 1rem;
		margin-top: 1px;
		text-align: center;
	}
}

@media (max-width: 640px) {
	.tabs-cont {
		order: 3;
	}
}
