.big-image {
	border-radius: 0;
	margin: 0;
	width: auto;
}

.big-image img {
	display: block;
	height: auto;
	max-height: 98vh;
	max-width: 100%;
	margin: 0 auto;
	width: auto;
}

.big-image .mobile-close {
	background-color: lightgray;
}

@media (max-width: 960px) {
	.big-image {
		max-width: 80vw;
	}
}

@media (max-width: 640px) {
	.big-image {
		max-width: 100%;
		width: 100%;
	}
}
