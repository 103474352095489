.footer {
	text-align: center;
	color: #aaa;
	font-size: 1.4rem;
}

.footer a {
	color: #aaa;
}

@media (max-width: 960px) {
	.footer {
		padding-bottom: 3rem;
	}
}
