.notification-cont {
	left: 50%;
	position: fixed;
	top: -80px;
	transform: translateX(-50%);
	transition: top 0.5s;
	z-index: 200;
}

.notification-cont.active {
	top: 1%;
}

.notification {
	background-color: rgb(28, 153, 28, 0.9);
	color: white;
	padding: 2.5rem 1rem;
	border-radius: 0.5rem;
	font-weight: 600;
}

@media (max-width: 640px) {
	.notification-cont {
		left: 0;
		top: -90px;
		transform: none;
	}

	.notification {
		text-align: center;
		width: 100vw;
	}
}
