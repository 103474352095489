.full-details {
	width: 75vw;
	padding-bottom: 2rem;
}

h2.full-details-heading {
	align-items: flex-end;
	background: rgb(221 235 221);
	border: solid 1px #abd5ab;
	display: flex;
	justify-content: space-between;
	margin-bottom: 2.5rem;
}

.full-details-heading.red {
	background: rgb(251 232 232);
	border: solid 1px #e9c4c4;
}

.full-details main {
	align-items: flex-start;
	display: flex;
	flex-wrap: wrap;
}

.full-details-left,
.full-details-center,
.full-details-right {
	flex: 1;
}

.full-details-left figure {
	margin: 0 3rem 0 3.5rem;
	text-align: right;
}

.full-details-left img {
	border-radius: 0.5rem;
	cursor: pointer;
	max-height: 75vh;
	max-width: 100%;
}

.full-details-right {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}

div.full-details-column {
	background-color: #eee;
	border: solid 1px #ddd;
	border-radius: 0.5rem;
	display: flex;
	flex-direction: column;
	margin-right: 3.5rem;
	padding: 2rem;
}

.full-details-column > div {
	margin-bottom: 1rem;
}

.full-details-column p {
	margin: 0 0 0.35rem 0;
}

.full-details-column ol {
	margin: 0;
	padding-left: 3rem;
}

.full-details-column > div > p:last-child {
	font-weight: 600;
}

.full-details-owned {
	color: green;
	font-weight: 600;
}

.full-details-not-owned {
	color: red;
	font-weight: 600;
}

.full-details-variant-link {
	color: green;
	cursor: pointer;
	font-weight: 600;
	text-decoration: underline;
}

.full-details-edit-cont {
	display: flex;
	justify-content: center;
}

.mobile-close {
	display: none;
	position: fixed;
	top: 0.75rem;
	right: 0.75rem;
	background-color: rgb(221 235 221);
	padding: 0.5rem 1rem;
	border-radius: 100px;
}

.full-details-heading.red .mobile-close {
	background-color: rgb(251 232 232);
}

.mobile-close .fa {
	font-size: 3rem;
}

@media (max-width: 1350px) {
	.full-details {
		width: 90vw;
	}
}

@media (max-width: 1024px) {
	.full-details {
		width: 85vw;
	}

	.full-details-left figure {
		text-align: center;
	}

	.full-details-left {
		flex-basis: 100%;
	}

	.full-details-left figure {
		margin: 0;
	}

	div.full-details-column {
		margin: 0 0.25rem;
	}

	.full-details main {
		align-items: stretch;
	}

	.full-details-column {
		height: 100%;
	}
}

@media (max-width: 640px) {
	.full-details {
		width: 98vw;
	}

	.full-details main {
		flex-direction: column;
	}

	div.full-details-column {
		background-color: white;
		border: none;
	}

	.full-details-center div.full-details-column {
		padding-bottom: 0;
	}

	.full-details-right div.full-details-column {
		padding-top: 0;
	}

	.mobile-close {
		display: block;
	}

	h2.full-details-heading {
		flex-wrap: wrap;
	}

	.full-details-edit-cont {
		justify-content: flex-start;
		width: 100%;
	}
}
