.utility-cont {
	align-items: center;
	display: flex;
	justify-content: flex-end;
}

.search-box {
	align-items: center;
	color: white;
	display: flex;
	font-size: 2rem;
	justify-content: flex-end;
}

.search-box input {
	background: #333;
	border: solid 1px #666;
	border-radius: 0.5rem;
	color: white;
	font-family: "Montserrat", sans-serif;
	font-size: 1.8rem;
	margin-left: 1rem;
	padding: 1.6rem;
	outline: none;
}

.search-box input::placeholder {
	color: #aaa;
}

.search-icon {
	cursor: pointer;
	padding: 0 2rem 0 0.5rem;
}

.clear-search {
	align-self: flex-end;
	color: #bbb;
	cursor: pointer;
	font-size: 1.5rem;
}

.clear-search:hover {
	color: white;
}

@media (max-width: 450px) {
	.search-box {
		flex-direction: column;
	}

	.search-box input {
		margin-left: 0;
	}

	.clear-search {
		align-self: flex-start;
		background-color: black;
		margin-left: 1rem;
		order: 2;
		padding: 0.25rem;
	}
}
