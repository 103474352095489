.filter-cont {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 4.5rem;
	position: relative;
	user-select: none;
}

.filter-info {
	display: flex;
	justify-content: space-between;
	margin-bottom: -1.5rem;
}

.result-count {
	display: flex;
	flex-wrap: wrap;
	margin-left: 1rem;
	text-align: right;
}

.result-count > div:first-child {
	margin-right: 2rem;
}

.filter-reset-cont {
	align-self: flex-end;
	margin-left: 1rem;
}

.reset-filters {
	align-self: flex-start;
	cursor: pointer;
	margin: 0;
	font-size: 1.4rem;
}

.show-advanced-filter {
	cursor: pointer;
	margin: 0 0 0 1.5rem;
	font-size: 1.4rem;
}

.legend-block-cont {
	align-items: center;
	display: flex;
	margin-right: 1rem;
	padding: 0 0 0.5rem 0;
}

.legend-block {
	width: 15px;
	height: 15px;
	display: block;
	margin: 0 0.5rem 0 1.75rem;
}

.legend-block-green {
	background-color: rgb(28, 153, 28);
}

.legend-block-yellow {
	background-color: rgb(185, 175, 22);
}

.legend-block-red {
	background-color: rgb(217 104 104);
}

.show-filters-btn {
	display: none;
	margin: 0 auto 2rem;
}

.mobile-filter-reset-cont {
	display: none;
	margin-bottom: 2rem;
	text-align: center;
}

@media (max-width: 1350px) {
	.filter-info {
		flex-direction: column;
	}

	.result-count {
		margin-left: 0;
		margin-bottom: 1rem;
		order: 2;
	}
}

.mobile-top {
	display: none;
	order: 2;
	padding: 0.5rem 1.5rem 0.5rem 2rem;
}

@media (max-width: 960px) {
	.filter-cont {
		align-items: flex-start;
		display: none;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 1rem;
	}

	.filter-cont.visible {
		display: flex;
	}

	.legend-block-cont {
		display: none;
	}

	.show-filters-btn {
		display: block;
		margin-bottom: 1rem;
	}

	.result-count {
		margin-bottom: 0;
		padding: 0.5rem 0 0.5rem 1.5rem;
	}

	.filter-options {
		width: 100%;
	}

	.filter-options > div {
		text-align: center;
	}

	.filter-reset-cont {
		margin: 1rem auto 0;
		text-align: center;
		width: 100%;
	}

	.filter-info {
		align-items: center;
		background-color: white;
		box-shadow: 0 0 5px 0 #aaa;
		bottom: 0;
		flex-direction: row;
		left: 0;
		margin-bottom: 0;
		position: sticky;
		top: 0;
		width: 100%;
	}

	.mobile-top {
		display: block;
	}

	.filter-reset-cont {
		display: none;
	}

	.mobile-filter-reset-cont {
		display: block;
	}
}
