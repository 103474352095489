* {
	box-sizing: border-box;
}

:root {
	--bodyColor: #ccc;
	--bodyMargin: 3rem;
	--mainBgColor: black;
	--tiny: 640px;
	--small: 960px;
	--medium: 1024px;
	--large: 1350px;
	--huge: 1700px;
}

#root {
	padding: 0.8rem;
	position: relative;
}

html {
	font-size: 62.5%;
}

body {
	background-color: var(--mainBgColor);
	background-image: url("images/stars.jpg");
	font-size: 1.6rem;
	font-family: "Montserrat", sans-serif;
	margin: 0;
}

.content-wrapper {
	background-color: var(--bodyColor);
	border: solid 1px #222;
	outline: solid 18px #eee;
	border-radius: 3rem;
	margin: var(--bodyMargin);
	margin-top: 2rem;
	min-height: calc(100vh - var(--bodyMargin) - var(--bodyMargin));
	padding: 1rem;
	position: relative;
}

p,
ul,
ol {
	font-size: 1.6rem;
}

input[type="submit"],
button {
	font-size: 16px;
	border-radius: 5px;
	background-color: #2f6e3a;
	border: none;
	padding: 5px 10px;
	cursor: pointer;
	color: white;
}

button.cancel {
	background-color: gray;
}

button.cancel:hover {
	background-color: #a7a7a7;
}

button.danger {
	background-color: rgb(187, 71, 71);
}

button.danger:hover {
	background-color: rgb(217 104 104);
}

button.basic {
	align-self: center;
	background-color: white;
	border: solid 1px #bbb;
	color: #444;
}

button.basic:hover {
	background-color: #eee;
}

input[type="submit"]:hover,
button:hover {
	background-color: #4a8f56;
}

input[disabled],
button[disabled] {
	background-color: lightgray;
	cursor: default;
}

input[disabled]:hover,
button[disabled]:hover {
	background-color: lightgray;
}

textarea {
	width: 100%;
}

.lock-site-cover {
	background-color: white;
	height: 100vh;
	left: 0;
	position: fixed;
	top: 0;
	width: 100vw;
}

.maintenance {
	align-items: center;
	color: white;
	flex-direction: column;
	font-size: 20px;
	display: flex;
	height: 100vh;
	width: 50vw;
	justify-content: center;
	margin: 0 auto;
	text-align: center;
}

@media (max-width: 640px) {
	#root {
		padding: 0.8rem 0;
	}

	.content-wrapper {
		border: none;
		margin: 0.25rem;
		outline: none;
	}
}
