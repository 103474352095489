.profile{
	max-width: 450px;
	width: 98vw;
}

.profile form {
	flex-direction: column;
	justify-content: flex-start;
}

.profile form label {
	font-weight: 600;
}

.profile form .fa-copy {
	cursor: pointer;
	margin-bottom: 1.25rem;
	padding-left: 0.75rem;
}

.shareable-url-cont {
	align-items: flex-end;
	display: flex;
}

.profile .shareable-url-cont label {
	font-weight: 400;
}

.profile .shareable-url-cont .form-control {
	flex: 1;
	margin-top: 0;
}
