.modal-cont {
	align-items: flex-start;
	bottom: 0;
	display: flex;
	height: 100vh;
	justify-content: center;
	position: fixed;
	top: 0;
	width: 100vw;
	overflow: auto;
	z-index: 100;
}

.overlay {
	background-color: rgba(0, 0, 0, 0.95);
	bottom: 0;
	height: 100vh;
	left: 0;
	padding: 2rem;
	position: sticky;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 100;
}

.overlay .fa-close {
	font-size: 36px;
	color: lightgray;
	cursor: pointer;
	float: right;
}

.overlay .fa-close:hover {
	color: white;
}

.modal-card {
	background-color: white;
	border-radius: 2rem;
	box-shadow: none;
	margin-top: 1%;
	max-width: 90%;
	position: absolute;
	width: 50vw;
	z-index: 100;
}

.modal-card > form {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	margin: 0 2rem 2rem 2rem;
}

.modal-card input[type="submit"],
.modal-card button {
	font-size: 1.6rem;
}

.modal-card h2 {
	align-items: flex-end;
	display: flex;
	justify-content: space-between;
	padding: 2rem;
	margin: 0 0 1.5rem 0;
	background-color: lightgray;
	border-radius: 2rem 2rem 0 0;
	border-bottom: solid 1px #ababab;
}

.modal-card h2.sign-up-heading {
	background-color: rgb(221 235 221);
}

.modal-card div.delete {
	cursor: pointer;
	color: red;
	margin-bottom: 2rem;
}

.modal-card div.validation {
	color: red;
	margin: 0 0 0.5rem 0;
	visibility: hidden;
}

.modal-card div.validation.visible {
	visibility: visible;
}

.form-message {
	font-size: 1.3rem;
	padding-top: 1.5rem;
}

.modal-card button.cancel {
	margin-left: 15px;
}

.checkbox-cont {
	align-items: center;
	display: flex;
}

.checkbox-cont input {
	margin: 0 0.75rem;
}

.edit-card .mobile-close,
.add-form .mobile-close {
	background-color: lightgray;
}

.signup-spinner-cont {
	align-items: center;
	display: flex;
	margin-top: 1rem;
	order: 2;
}

.signup-spinner-cont p {
	color: #009119;
	font-size: 1.4rem;
	margin: 0 1rem 0 0;
}


@media (max-width: 1700px) {
	.modal-card.edit-card,
	.modal-card.add-form {
		width: 60vw;
	}
}

@media (max-width: 1350px) {
	.modal-card.edit-card,
	.modal-card.add-form {
		width: 80vw;
	}
}

@media (max-width: 640px) {
	.modal-card {
		max-width: 98%;
	}

	.modal-card h2 {
		padding-right: 4rem;
	}

	.modal-card.edit-card,
	.modal-card.add-form {
		width: 98vw;
	}

	.modal-card.edit-card form,
	.modal-card.add-form form {
		flex-direction: column;
	}

	.modal-card.edit-card form .form-column,
	.modal-card.add-form form .form-column {
		padding: 0;
		width: 100%;
	}

	.modal-card.edit-card h2 {
		flex-direction: column;
		align-items: flex-start;
	}

	.modal-card.edit-card h2 div:last-child {
		display: none;
	}

	.overlay .fa-close {
		display: none;
	}

	.edit-card .edit-save-cancel,
	.add-form .edit-save-cancel {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100vw;
		justify-content: center;
		background-color: lightgray;
		padding: 1.5rem 0 0 1.5rem;
	}

	.edit-card .edit-save-cancel button,
	.edit-card .edit-save-cancel input,
	.add-form .edit-save-cancel button,
	.add-form .edit-save-cancel input {
		margin: 0 1.5rem 1.5rem 0;
	}

	.edit-card > form,
	.add-form > form {
		margin-bottom: 7rem;
	}
}
