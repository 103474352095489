.image-upload-preview img {
	max-width: 100%;
}

.form-image-links{
  display: flex;
  justify-content: space-between;
}

.form-image-cancel{
  color: red;
  cursor: pointer;
}