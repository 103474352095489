.filter {
	align-items: stretch;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 0.25rem 0.25rem 0;
}

.filter-heading {
	background-color: #eee;
	border-bottom: solid 1px #ccc;
	font-size: 1.3rem;
	font-weight: 600;
	padding: 0.5rem 1rem;
	text-align: center;
	text-transform: uppercase;
}

.filter-options {
	align-self: center;
	display: flex;
}

.show-options {
	align-self: stretch;
	text-align: center;
}

div.filter-options > div.options-show-hide {
	border-bottom: solid 2px transparent;
}

.options-show-hide.active {
	background-color: white;
}

.filter-options-cont {
	display: flex;
	position: absolute;
	left: 0;
	margin-left: 3px;
	top: 107%;
}

.filter-options-section {
	align-items: center;
	background-color: #ddd;
	display: flex;
	margin-right: 0.5rem;
}

.filter-options-section:last-child {
	margin-right: 0;
}

.options-section-title {
	font-weight: 600;
	margin: 0 0.75rem;
}

.filter-options-section label:first-child {
	font-weight: 600;
	margin-right: 0.75rem;
}

.filter-options > div {
	background-color: #ddd;
	border-right: solid 1px #ccc;
	cursor: pointer;
	flex: 1;
	font-size: 1.4rem;
	padding: 0.25rem 0.75rem;
}

.filter-options > div:last-child {
	border: none;
}

.filter-options > div:hover {
	background-color: #eee;
}

.filter-options > div.active {
	background-color: white;
	border-bottom: solid 2px rgb(28, 153, 28);
}

.option-item {
	border-bottom: solid 2px transparent;
	cursor: pointer;
	margin-right: 1px;
	padding: 0.25rem 0.75rem;
}

.option-item.active {
	background-color: white;
	border-bottom: solid 2px rgb(28, 153, 28);
}

.condition-filter .filter-heading {
	padding: 0.5rem 2.5rem;
}

@media (max-width: 960px) {
	.filter {
		margin: 0 0 1rem 0;
		flex-basis: 49%;
	}

	.filter-options-cont {
		flex-wrap: wrap;
		margin-left: 0;
		position: static;
	}

	.filter-options-section {
		background: none;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 0.5rem;
		margin-right: 0;
		width: 100%;
	}

	.options-section-title {
		width: 100%;
		margin-bottom: 0.5rem;
		margin-left: 0;
		margin-right: 0;
		text-align: center;
	}
}

@media (max-width: 640px) {
	.filter {
		flex-basis: 99%;
	}
}
